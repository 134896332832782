import { Property as CSSProperty } from 'csstype';
import styled, { css } from 'styled-components';

import { StyledComponentPropsWithoutRef } from '@dop/shared/typeHelpers/StyledComponentPropsWithoutRef';

import {
	BaseStaticStyleProps,
	baseStaticStyleMap,
	baseStyle,
} from '../base/Base';
import { getCSSFromStyleProps } from '../base/getCSSFromStyleProps';
import {
	ComponentStyleMap,
	ComponentStyleProps,
	StyleMap,
} from '../base/StyleProps.types';
import {
	NegativeColor,
	ColorTheme,
	PositiveColor,
} from '../colorTheme/ColorTheme.types';
import { getColorThemeCSS } from '../colorTheme/colorThemeStyleFunctions';
import {
	Border,
	defaultPlaneDivisionTransition,
	Outline,
	Padding,
	PaddingBlock,
	PaddingInline,
} from './planeDivisionDefinitions';
import {
	getBorderRadiusCSS,
	getNormalBorderColorCSS,
	getBoxShadowCSS,
	getPaddingCSS,
	getNormalBackgroundColorCSS,
	getBorderCSS,
	getBorderSideCSS,
	getBackgroundClipCSS,
	getActiveBackgroundColorCSS,
	getHoverBackgroundColorCSS,
	getHoverBorderColorCSS,
	getActiveBorderColorCSS,
	getBackdropFilterCSS,
	getBackgroundImageCSS,
	getBackgroundPositionCSS,
	getBackgroundSizeCSS,
	getNormalOutlineCSS,
	getInteractiveOutlineCSS,
	getPaddingInlineCSS,
	getPaddingBlockCSS,
} from './planeDivisionStyleFunctions';

export type BoxStaticStyleProps = BaseStaticStyleProps & {
	$padding?: Padding;
	$paddingInline?: PaddingInline;
	$paddingBlock?: PaddingBlock;
	$borderRadius?: CSSProperty.BorderRadius;
	$boxShadow?: CSSProperty.BoxShadow;
	$colorTheme?: ColorTheme;
	$border?: Border;
	$borderInline?: Border;
	$borderBlock?: Border;
	$borderInlineStart?: Border;
	$borderInlineEnd?: Border;
	$borderBlockStart?: Border;
	$borderBlockEnd?: Border;
	$backgroundClip?: CSSProperty.BackgroundClip;
	$backdropFilter?: CSSProperty.BackdropFilter;
	$backgroundImage?: CSSProperty.BackgroundImage;
	$backgroundSize?: CSSProperty.BackgroundSize | number;
	$backgroundPosition?: CSSProperty.BackgroundPosition | number;
};

export type BoxInteractiveStyleProps = {
	$backgroundColor?: PositiveColor | NegativeColor;
	$borderColor?: PositiveColor | NegativeColor;
	$outline?: Outline;
	$transform?: CSSProperty.Transform;
	$translate?: CSSProperty.Translate;
	$rotate?: CSSProperty.Rotate;
	$scale?: CSSProperty.Scale;
	$filter?: CSSProperty.Filter;
	$clipPath?: CSSProperty.ClipPath;
};

export type BoxComponentStyleProps = ComponentStyleProps<
	BoxStaticStyleProps,
	BoxInteractiveStyleProps
>;

export const boxStaticStyleMap: StyleMap<
	BoxStaticStyleProps & BoxInteractiveStyleProps
> = {
	...baseStaticStyleMap,
	$transition: (transition = defaultPlaneDivisionTransition) =>
		baseStaticStyleMap.$transition(transition),
	$outline: getNormalOutlineCSS,
	$padding: getPaddingCSS,
	$paddingInline: getPaddingInlineCSS,
	$paddingBlock: getPaddingBlockCSS,
	$borderRadius: getBorderRadiusCSS,
	$border: getBorderCSS,
	$borderInline: getBorderSideCSS('inline'),
	$borderBlock: getBorderSideCSS('block'),
	$borderInlineStart: getBorderSideCSS('inline-start'),
	$borderInlineEnd: getBorderSideCSS('inline-end'),
	$borderBlockStart: getBorderSideCSS('block-start'),
	$borderBlockEnd: getBorderSideCSS('block-end'),
	$borderColor: getNormalBorderColorCSS,
	$backgroundColor: getNormalBackgroundColorCSS,
	$backgroundImage: getBackgroundImageCSS,
	$backgroundSize: getBackgroundSizeCSS,
	$backgroundPosition: getBackgroundPositionCSS,
	$boxShadow: getBoxShadowCSS,
	$colorTheme: getColorThemeCSS,
	$backgroundClip: getBackgroundClipCSS,
	$backdropFilter: getBackdropFilterCSS,
	$clipPath: (clipPath) =>
		clipPath &&
		css`
			clip-path: ${clipPath};
		`,
};

export const boxInteractiveStyleMap: StyleMap<BoxInteractiveStyleProps> = {
	$outline: getInteractiveOutlineCSS('normal'),
	$borderColor: getNormalBorderColorCSS,
	$backgroundColor: boxStaticStyleMap.$backgroundColor,
	$transform: baseStaticStyleMap.$transform,
	$translate: baseStaticStyleMap.$translate,
	$rotate: baseStaticStyleMap.$rotate,
	$scale: baseStaticStyleMap.$scale,
	$filter: baseStaticStyleMap.$filter,
	$clipPath: (clipPath) =>
		clipPath &&
		css`
			clip-path: ${clipPath};
		`,
};

const boxStyleMap: ComponentStyleMap<
	BoxStaticStyleProps,
	BoxInteractiveStyleProps
> = {
	normal: boxStaticStyleMap,
	hover: {
		...boxInteractiveStyleMap,
		$borderColor: getHoverBorderColorCSS,
		$backgroundColor: getHoverBackgroundColorCSS,
		$outline: getInteractiveOutlineCSS('hover'),
	},
	active: {
		...boxInteractiveStyleMap,
		$borderColor: getActiveBorderColorCSS,
		$backgroundColor: getActiveBackgroundColorCSS,
		$outline: getInteractiveOutlineCSS('active'),
	},
	focus: boxInteractiveStyleMap,
	checked: boxInteractiveStyleMap,
	disabled: boxInteractiveStyleMap,
};

/**
 * Component for visual plain division, not layout
 *
 * Responsibilities:
 *
 * 1. Visually distinguish a box from its surroundings by giving it a different background color.
 * 2. Visually distinguish a box from its surroundings by giving it a border.
 * 3. Visually distinguish a box from its surroundings by giving it a shadow.
 * 4. Padding inside the box so elements can be placed inside the box without hitting the side of the box.
 */
export const Box = styled.span<BoxComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			display: block;
			${getCSSFromStyleProps(boxStyleMap, props)};
		`;
	}};
`;
export type BoxProps = StyledComponentPropsWithoutRef<typeof Box>;
