import { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { FlattenSimpleInterpolationMaybe } from './StyleProps.types';
import { AccHide } from './baseDefinitions';

type StringOrRootCap = string | number;

export const getStringOrRootCapCSSValue = (value: StringOrRootCap): string => {
	if (typeof value === 'number') {
		return `calc(var(--root-cap) * ${value})`;
	}

	return value;
};

export const getStringOrRootCapArrayCSSValue = (
	insetValue: StringOrRootCap | Array<StringOrRootCap>
): string => {
	if (Array.isArray(insetValue)) {
		return insetValue.map(getStringOrRootCapCSSValue).join(' ');
	}

	return getStringOrRootCapCSSValue(insetValue);
};

export const getVerticalAlignCSS = (
	verticalAlign?: CSSProperty.VerticalAlign
) =>
	verticalAlign != null &&
	css`
		vertical-align: ${verticalAlign};
	`;

export const getDisplayCSS = (
	display?: CSSProperty.Display
): FlattenSimpleInterpolationMaybe =>
	display != null &&
	css`
		display: ${display};
	`;

export const getPositionCSS = (position?: CSSProperty.Position) =>
	position != null &&
	css`
		position: ${position};
	`;

const accHideCSS = css`
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	inline-size: 1px;
	block-size: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
`;

export const getAccHideCSS = (accHide: AccHide = false) => {
	if (accHide === 'focusable') {
		return css`
			&:not(:focus) {
				${accHideCSS};
			}
		`;
	}
	return accHide && accHideCSS;
};
