import { css } from 'styled-components';

import { FlattenSimpleInterpolationMaybe } from '../base/StyleProps.types';
import {
	AnyColor,
	AnyThemedColor,
	categoryKeys,
	ColorTheme,
	StateColors,
} from './ColorTheme.types';

/**
 * Filter out 'normal' name fragment including its dash
 */

const getColorCustomProperty = (
	[categoryName, variantName]: AnyThemedColor,
	stateName: keyof StateColors = 'normal'
): string => {
	const nameFragments = [`--color`, categoryName];
	if (variantName !== 'normal') nameFragments.push(variantName);
	if (stateName !== 'normal') nameFragments.push(stateName);

	return nameFragments.join('-');
};

export const getColorThemeCSS = (
	theme?: ColorTheme
): FlattenSimpleInterpolationMaybe => {
	if (theme == null) return false;

	const colorThemeCSS: Array<FlattenSimpleInterpolationMaybe> = [];
	for (const categoryName of categoryKeys) {
		const category = theme[categoryName];
		for (const [variantName, variant] of Object.entries(category)) {
			for (const [stateName, stateColor] of Object.entries(variant)) {
				colorThemeCSS.push(
					css`
						${getColorCustomProperty(
							// Typecasting to fix shortcoming in typescript handling of Object.entries
							[categoryName, variantName as keyof typeof category],
							stateName as keyof typeof variant
						)}: ${stateColor};
					`
				);
			}
		}
	}

	// Fix for extra specificity (&&), needed to prevent override of other $colorThemes
	return css`
		&&,
		&& *::before,
		&& *::after,
		&& *::backdrop {
			${colorThemeCSS};
		}
	`;
};

/**
 * When `color` is a keyPath this returns the CSS custom property for the theme color.
 *
 * When `color` is a string it returns the string itself. This way colors can be set directly.
 */
export const getThemedColorValue = (
	color: AnyColor,
	stateName: keyof StateColors = 'normal'
): string => {
	if (!Array.isArray(color)) return color;

	return `var(${getColorCustomProperty(color, stateName)})`;
};
