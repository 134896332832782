import { css } from 'styled-components';

import { FlattenSimpleInterpolationMaybe } from './StyleProps.types';

/**
 * Takes a map of media queries to values and generates CSS
 * with the media queries applied using `getCSS` to obtain
 * the CSS for each value.
 *
 * @param getCSS A function that takes a value and returns CSS.
 * @param mqToValueMap A map of media queries to values. 
 * The key all is used to generate CSS without a media query.
 * 
 * @example
 * ```tsx
 getCSSForResponsiveProp(
	rootCapInPx => {
		return css`
			--root-cap: ${rootCapInPx}px;
		`;
	},
	{
		all: 11,
		'(min-width: 880px)': 12,
		'(min-width: 1296px)': 14,
	}
)
// Returns:
// css`
//	--root-cap: 11px;
//	;@media (min-width: 880px) {
//		--root-cap: 12px;
//	}
//	;@media (min-width: 1296px) {
//		--root-cap: 14px;
//	}
 ```
 */
export const getCSSForResponsiveProp = <ValueType,>(
	getCSS: (value: ValueType) => FlattenSimpleInterpolationMaybe,
	mqToValueMap: { [MqText in string]: ValueType }
): FlattenSimpleInterpolationMaybe => {
	const CSS: Array<FlattenSimpleInterpolationMaybe> = [];
	for (const [mqText, value] of Object.entries(mqToValueMap)) {
		CSS.push(css`
			@media ${mqText} {
				${getCSS(value)};
			}
		`);
	}

	return CSS;
};
