import React from 'react';
import classnames from 'classnames';

import { set } from './functional';
import { map } from './immutableHelpersWithArgs';
import { toObject } from './immutableHelpersWithoutArgs';

export const themify = (themeBaseClass, theme, ...restClassNames) =>
	classnames(
		themeBaseClass,
		{ [`${themeBaseClass}--${theme}`]: theme != null },
		...restClassNames
	);

export const themifyProps =
	(themeBaseClass, propName = 'className') =>
	(props = {}) =>
		set(propName, themify(themeBaseClass, props.theme, props[propName]), props);

/**
 * Map each item as props to a React component
 * @param {Component} Component
 * @param {Object} defaultProps
 * @return {function}
 */
export const mapToComponent = (Component, defaultProps = {}) =>
	/**
	 * @param {Iterable} iterable
	 * @return {Iterable}
	 */
	{
		const { key = (_item, index) => index } = defaultProps;
		return map((item, index) => (
			<Component
				{...defaultProps}
				{...toObject(item)}
				key={key(item, index)}
				index={index}
			/>
		));
	};
