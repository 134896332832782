import { css } from 'styled-components';
import { Map } from 'immutable';

import theme from 'moduleAlias/project/src/styles/theme';

import {
	getInObject,
	immutableToObject,
	isEmptyObject,
	unexisty,
	isArray,
} from '../helpers/functional';
import { subtractionUnit } from './unitCalculationHelpers';

const sixteenth = 1 / 16; // eslint-disable-line no-magic-numbers

/**
 * Matches all screens
 */
export const all = 'all';

/**
 * Breakpoint 352px
 */
export const extraExtraSmall = 'extraExtraSmall';

/**
 * Breakpoint 384px
 */
export const extraSmall = 'extraSmall';

/**
 * Breakpoint 560px
 */
export const small = 'small';

/**
 * Breakpoint 640px
 */
export const smallMedium = 'smallMedium';

/**
 * Breakpoint 720px
 */
export const medium = 'medium';

/**
 * Breakpoint 800px
 */
export const mediumLarge = 'mediumLarge';

/**
 * Breakpoint 880px
 */
export const large = 'large';

/**
 * Breakpoint 912px
 */
export const extraLarge = 'extraLarge';

/**
 * Breakpoint 1120px
 */
export const extraExtraLarge = 'extraExtraLarge';

/**
 * Breakpoint 1296px
 */
export const extraExtraExtraLarge = 'extraExtraExtraLarge';

/**
 * Breakpoint 1470px
 */
export const extraExtraExtraExtraLarge = 'extraExtraExtraExtraLarge';

/**
 * Breakpoint 1920px
 */
export const largest = 'largest';

/**
 * Breakpoint 464px
 */
export const smallHeight = 'smallHeight';

/**
 * Breakpoint 544px
 */
export const smallMediumHeight = 'smallMediumHeight';

/**
 * Breakpoint 640px
 */
export const mediumHeight = 'mediumHeight';

export const twoColumnGridBreakpoint = smallMedium;
export const threeColumnGridBreakpoint = extraExtraLarge;

const mediaQueries = {
	[extraExtraSmall]: {
		greater: '(min-width: 22em)',
		lesser: `(max-width: ${subtractionUnit('22em', sixteenth)})`,
	},
	[extraSmall]: {
		greater: '(min-width: 24em)',
		lesser: `(max-width: ${subtractionUnit('24em', sixteenth)})`,
	},
	[small]: {
		greater: '(min-width: 35em)',
		lesser: `(max-width: ${subtractionUnit('35em', sixteenth)})`,
	},
	[smallMedium]: {
		greater: '(min-width: 40em)',
		lesser: `(max-width: ${subtractionUnit('40em', sixteenth)})`,
	},
	[medium]: {
		greater: '(min-width: 45em)',
		lesser: `(max-width: ${subtractionUnit('45em', sixteenth)})`,
	},
	[mediumLarge]: {
		greater: '(min-width: 50em)',
		lesser: `(max-width: ${subtractionUnit('50em', sixteenth)})`,
	},
	[large]: {
		greater: '(min-width: 55em)',
		lesser: `(max-width: ${subtractionUnit('55em', sixteenth)})`,
	},
	[extraLarge]: {
		greater: '(min-width: 57em)',
		lesser: `(max-width: ${subtractionUnit('57em', sixteenth)})`,
	},
	[extraExtraLarge]: {
		greater: '(min-width: 70em)',
		lesser: `(max-width: ${subtractionUnit('70em', sixteenth)})`,
	},
	[extraExtraExtraLarge]: {
		greater: '(min-width: 81em)',
		lesser: `(max-width: ${subtractionUnit('81em', sixteenth)})`,
	},
	[extraExtraExtraExtraLarge]: {
		greater: '(min-width: 92em)',
		lesser: `(max-width: ${subtractionUnit('92em', sixteenth)})`,
	},
	[largest]: {
		greater: `(min-width: ${theme.maxPageWidth.em})`,
		lesser: `(max-width: ${theme.maxPageWidth.em - sixteenth})`,
	},
	[smallHeight]: {
		greater: `(min-height: 29em)`,
		lesser: `(max-width: ${subtractionUnit('29em', sixteenth)})`,
	},
	[smallMediumHeight]: {
		greater: `(min-height: 34em)`,
		lesser: `(max-width: ${subtractionUnit('34em', sixteenth)})`,
	},
	[mediumHeight]: {
		greater: `(min-height: 40em)`,
		lesser: `(max-width: ${subtractionUnit('40em', sixteenth)})`,
	},
};

export const getMediaQuery = (name, type) => {
	return getInObject([name, type])(mediaQueries);
};

export const getMediaQueryString = (props) => {
	if (isEmptyObject(props)) {
		return all;
	}

	const convertedProps = Map.isMap(props) ? immutableToObject(props) : props;
	const { from, to } = convertedProps;
	const mediaFrom = getMediaQuery(from, 'greater');
	const mediaTo = getMediaQuery(to, 'lesser');

	if (unexisty(mediaFrom) && unexisty(mediaTo)) {
		return all;
	}

	if (mediaFrom && mediaTo) {
		return `${mediaFrom} and ${mediaTo}`;
	}

	return mediaFrom || mediaTo;
};

/**
 * Returns the correct mediaquery based on passed from and/or to entry.
 *
 * Examples:
 * mq({ from: medium })(...args) = @media (min-width: 45em) { ...args }
 * mq({ to: medium }) = @media (max-width: 44em) { ...args }
 * mq({ from: medium, to: mediumLarge }) = @media (min-width: 45em) and (max-width: 45em) { ...args }
 *
 * @param {object} props}
 */
export const mq =
	(props) =>
	(...templateLiteral) => {
		const mediaQueryString = getMediaQueryString(props);
		const cssProps = css(...templateLiteral);

		return mediaQueryString === all
			? cssProps
			: css`
					@media ${mediaQueryString} {
						${cssProps};
					}
			  `;
	};

const mqTupleToMqObject = (fromOrTo, [mqName, value]) => {
	return {
		[fromOrTo]: mqName,
		value,
	};
};

const normalizeValueForMq = (fromOrTo, valueForMq) => {
	return Map(
		isArray(valueForMq) ? mqTupleToMqObject(fromOrTo, valueForMq) : valueForMq
	);
};

/**
 * Example:
 * cssForMqs('width', 'from')({from: small, to: medium, value: '50%'}, {from: medium, value: '33%'}, [large, '20%'])
 */
export const cssForMqs =
	(cssPropName, defaultFromOrTo = 'from') =>
	(...valuesForMqs) => {
		const cssList = valuesForMqs.map((valueForMq) => {
			const mqProps = normalizeValueForMq(defaultFromOrTo, valueForMq);

			return mq(mqProps)`${cssPropName}: ${mqProps.get('value')};`;
		});

		return cssList;
	};
