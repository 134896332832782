'use client';

import { usePageDataSelector } from '@/globals/dataProviders/usePageDataSelector';
import { PropsWithChildren } from 'react';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { box } from '@/uiPrimitives/layout/box';
import { getIn } from '../../helpers/immutableHelpersWithArgs';

export const YourEuropeLegacyClient = ({ children }: PropsWithChildren) => {
	const sdg = usePageDataSelector(getIn(['sdg']));

	if (sdg == null) return null;

	return (
		<div className={classes(box({ paddingBlock: '5 | betweenParagraphs' }))}>
			{children}
		</div>
	);
};
