import { createGlobalStyle } from 'styled-components';

import { getColorThemeCSS } from './colorThemeStyleFunctions';
import { BGOVColorTheme, OPColorTheme } from './ColorTheme.types';

export const RootColorTheme = createGlobalStyle<{
	rootColorTheme: OPColorTheme | BGOVColorTheme;
}>`
    :root {
        ${({ rootColorTheme }) => getColorThemeCSS(rootColorTheme)}
    }
`;
