//
// Media queries can be combined by interpolation
//
// For example:
//
// `${mqWidth640px.min} and ${mqWidth1120px.max}`
//
// `${mqWidth640px.min}, ${mqHeight464px.min}`
//

//
// MQ var names should start with mq, followed by the
// media feature it is testing
//
// In case of dimension media queries the breakpoint should be specified last in PX
// The MQ value for dimension queries should be an object with min and max as keys
//
// Example:
// export const mqWidth640px = {
//  min: `(min-width: 640px)`,
//  max: `(max-width: 639px)`,
// } as const;
//
// For onther media queries such as `pointer` the object should contain the possible values
//
// export const mqPointer = {
//  none: `(pointer: none)`,
//  coarse: `(pointer: coarse)`,
//  fine: `(pointer: fine)`,
// } as const;
//

//
// Width media queries
//

/**
 * Column layout breakpoint: 1 <-> 2 columns
 */
export const mqWidth640px = {
	min: `(min-width: 640px)`,
	max: `(max-width: 639px)`,
} as const;

export const mqWidth740px = {
	min: `(min-width: 740px)`,
	max: `(max-width: 739px)`,
} as const;
/**
 * RootCap breakpoint: 11 <-> 12
 */
export const mqWidth880px = {
	min: `(min-width: 880px)`,
	max: `(max-width: 879px)`,
} as const;
/**
 * Column layout breakpoint: 2 <-> 3 columns
 */
export const mqWidth1120px = {
	min: `(min-width: 1120px)`,
	max: `(max-width: 1119px)`,
} as const;
/**
 * RootCap breakpoint: 12 <-> 14
 */
export const mqWidth1296px = {
	min: `(min-width: 1296px)`,
	max: `(max-width: 1295px)`,
} as const;
