import { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { FlattenSimpleInterpolationMaybe } from '../base/StyleProps.types';
import {
	positiveKeysToNegativeTextKeys,
	ShapeColor,
	StateColors,
	TextColor,
} from '../colorTheme/ColorTheme.types';
import { getThemedColorValue } from '../colorTheme/colorThemeStyleFunctions';
import {
	Padding,
	Border,
	Outline,
	PaddingBlock,
	PaddingInline,
} from './planeDivisionDefinitions';
import {
	activeDescendantsSelector,
	activeSelfSelector,
	hoverDescendantsSelector,
	hoverSelfSelector,
} from '../base/getCSSFromStyleProps';
import { getStringOrRootCapCSSValue } from '../base/commonStyleFunctions';

const getPaddingValue = (padEntry: number) => {
	return `calc(var(--root-cap) * ${Math.abs(padEntry)})`;
};

const getMarginValue = (padEntry: number) => {
	if (padEntry < 0) {
		return `calc(var(--root-cap) * ${padEntry})`;
	}
	return `0`;
};
/**
 * A negative value for any of the padding sides
 * will result in a positive padding and a negative margin on that side.
 * 
 * Any positive value for the padding will result in 0 margin on that side.
 * 
 * This is useful when the padding should not alter the text position
 * but you do need some space arround the text to for example
 * add a background color or a border.
 * 
 * Negative and positive values can be combined.
 * 
 * @example
```tsx
getPaddingCSS([-1, 2]);
// Returns:
// css`
//   padding: calc(var(--root-cap) * 1) calc(var(--root-cap) * 2);
//   margin: calc(var(--root-cap) * -1) 0;
// `
```
 */
export const getPaddingCSS = (
	padding: Padding = 0
): FlattenSimpleInterpolationMaybe => {
	if (Array.isArray(padding)) {
		const paddingPropertyValues = padding.map(getPaddingValue);

		const marginPropertyValues = padding.map(getMarginValue);

		// full description of inline/block start & end
		if (padding.length === 4) {
			const [
				paddingBlockStart,
				paddingInlineEnd,
				paddingBlockEnd,
				paddingInlineStart,
			] = paddingPropertyValues;
			const [
				marginBlockStart,
				marginInlineEnd,
				marginBlockEnd,
				marginInlineStart,
			] = marginPropertyValues;

			return css`
				padding-inline: ${paddingInlineStart} ${paddingInlineEnd};
				padding-block: ${paddingBlockStart} ${paddingBlockEnd};
				margin-inline: ${marginInlineStart} ${marginInlineEnd};
				margin-block: ${marginBlockStart} ${marginBlockEnd};
			`;
		}

		// short-hand [a, b, c] (inline-start and inline-end will be identical)
		if (padding.length === 3) {
			const [paddingBlockStart, paddingInline, paddingBlockEnd] =
				paddingPropertyValues;
			const [marginBlockStart, marginInline, marginBlockEnd] =
				marginPropertyValues;

			return css`
				padding-inline: ${paddingInline} ${paddingInline};
				padding-block: ${paddingBlockStart} ${paddingBlockEnd};
				margin-inline: ${marginInline} ${marginInline};
				margin-block: ${marginBlockStart} ${marginBlockEnd};
			`;
		}

		// short-hand [a, b] (inline/block-start and inline/block-end will be identical)
		const [paddingBlock, paddingInline] = paddingPropertyValues;
		const [marginBlock, marginInline] = marginPropertyValues;

		return css`
			padding-inline: ${paddingInline};
			padding-block: ${paddingBlock};
			margin-inline: ${marginInline};
			margin-block: ${marginBlock};
		`;
	}

	const marginValue = getMarginValue(padding);
	const paddingValue = getPaddingValue(padding);

	return css`
		padding-inline: ${paddingValue};
		padding-block: ${paddingValue};
		margin-inline: ${marginValue};
		margin-block: ${marginValue};
	`;
};

export const getPaddingInlineCSS = (paddingInline?: PaddingInline) => {
	if (paddingInline == null) return false;

	if (Array.isArray(paddingInline)) {
		const [paddingInlineStart, paddingInlineEnd] = paddingInline;

		return css`
			padding-inline: ${getPaddingValue(paddingInlineStart)}
				${getPaddingValue(paddingInlineEnd)};
			margin-inline: ${getMarginValue(paddingInlineStart)}
				${getMarginValue(paddingInlineEnd)};
		`;
	}

	return css`
		padding-inline: ${getPaddingValue(paddingInline)};
		margin-inline: ${getMarginValue(paddingInline)};
	`;
};

export const getPaddingBlockCSS = (paddingBlock?: PaddingBlock) => {
	if (paddingBlock == null) return false;

	if (Array.isArray(paddingBlock)) {
		const [paddingBlockStart, paddingBlockEnd] = paddingBlock;

		return css`
			padding-block: ${getPaddingValue(paddingBlockStart)}
				${getPaddingValue(paddingBlockEnd)};
			margin-block: ${getMarginValue(paddingBlockStart)}
				${getMarginValue(paddingBlockEnd)};
		`;
	}

	return css`
		padding-block: ${getPaddingValue(paddingBlock)};
		margin-block: ${getMarginValue(paddingBlock)};
	`;
};

const getInteractiveOutlineColorStyle = (outlineColor: ShapeColor) => {
	return css`
		${hoverSelfSelector},
		${hoverDescendantsSelector} {
			outline-color: ${getThemedColorValue(outlineColor, 'hover')};
		}

		${activeSelfSelector},
		${activeDescendantsSelector} {
			outline-color: ${getThemedColorValue(outlineColor, 'active')};
		}
	`;
};

export const getNormalOutlineCSS = (
	outline?: Outline
): FlattenSimpleInterpolationMaybe => {
	if (outline == null) return null;

	const [outlineWidth, outlineStyle, outlineColor] = outline;
	return css`
		outline: ${getThemedColorValue(outlineColor, 'normal')} ${outlineStyle}
			${outlineWidth};

		${getInteractiveOutlineColorStyle(outlineColor)};
	`;
};

export const getInteractiveOutlineCSS =
	(stateName: keyof StateColors) =>
	(outline?: Outline): FlattenSimpleInterpolationMaybe => {
		if (outline == null) return null;

		const [outlineWidth, outlineStyle, outlineColor] = outline;
		return css`
			outline: ${getThemedColorValue(outlineColor, stateName)} ${outlineStyle}
				${outlineWidth};
		`;
	};

export const getBorderRadiusCSS = (
	borderRadius: CSSProperty.BorderRadius = 0
): FlattenSimpleInterpolationMaybe =>
	css`
		border-radius: ${borderRadius};
	`;

const getInteractiveBorderColorStyle = (borderColor: ShapeColor) => {
	return css`
		${hoverSelfSelector},
		${hoverDescendantsSelector} {
			border-color: ${getThemedColorValue(borderColor, 'hover')};
		}

		${activeSelfSelector},
		${activeDescendantsSelector} {
			border-color: ${getThemedColorValue(borderColor, 'active')};
		}
	`;
};

export const getBorderCSS = (
	border?: Border
): FlattenSimpleInterpolationMaybe => {
	if (border == null) return null;

	const [borderWidth, borderStyle, borderColor] = border;
	return css`
		border: ${borderWidth} ${borderStyle}
			${getThemedColorValue(borderColor, 'normal')};

		${getInteractiveBorderColorStyle(borderColor)};
	`;
};

export const getBorderSideCSS =
	(
		side:
			| 'inline'
			| 'block'
			| 'inline-start'
			| 'inline-end'
			| 'block-start'
			| 'block-end'
	) =>
	(border?: Border): FlattenSimpleInterpolationMaybe => {
		if (border == null) return null;

		const [borderWidth, borderStyle, borderColor] = border;
		return css`
			border-${side}: ${borderWidth} ${borderStyle}
				${getThemedColorValue(borderColor, 'normal')};

			${hoverSelfSelector},
			${hoverDescendantsSelector} {
				border-${side}-color: ${getThemedColorValue(borderColor, 'hover')};
			}
	
			${activeSelfSelector},
			${activeDescendantsSelector} {
				border-${side}-color: ${getThemedColorValue(borderColor, 'active')};
			}
		`;
	};

export const getNormalBorderColorCSS = (
	borderColor?: ShapeColor
): FlattenSimpleInterpolationMaybe => {
	return (
		borderColor != null &&
		css`
			border-color: ${getThemedColorValue(borderColor, 'normal')};

			${getInteractiveBorderColorStyle(borderColor)};
		`
	);
};

export const getHoverBorderColorCSS = (
	borderColor?: ShapeColor
): FlattenSimpleInterpolationMaybe => {
	return (
		borderColor != null &&
		css`
			border-color: ${getThemedColorValue(borderColor, 'hover')};
		`
	);
};
export const getActiveBorderColorCSS = (
	borderColor?: ShapeColor
): FlattenSimpleInterpolationMaybe => {
	return (
		borderColor != null &&
		css`
			border-color: ${getThemedColorValue(borderColor, 'active')};
		`
	);
};

export const getBackgroundImageCSS = (
	backgroundImage?: CSSProperty.BackgroundImage
) =>
	backgroundImage != null &&
	css`
		background-image: ${backgroundImage};
	`;
export const getBackgroundSizeCSS = (
	backgroundSize?: CSSProperty.BackgroundSize | number
) =>
	backgroundSize != null &&
	css`
		background-size: ${getStringOrRootCapCSSValue(backgroundSize)};
	`;
export const getBackgroundPositionCSS = (
	backgroundPosition?: CSSProperty.BackgroundPosition | number
) =>
	backgroundPosition != null &&
	css`
		background-position: ${getStringOrRootCapCSSValue(backgroundPosition)};
	`;

const getNormalBackgroundContrastColorCSS = (backgroundColor: ShapeColor) => {
	if (backgroundColor === 'transparent') {
		return css`
			--color: unset;
			--color-hover: unset;
			--color-active: unset;
		`;
	}
	if (typeof backgroundColor === 'string') return undefined;

	const [categoryName, variantName] = backgroundColor;

	if (categoryName === 'positive') {
		const negativeTextKey = positiveKeysToNegativeTextKeys[variantName];
		const color: TextColor = ['text', negativeTextKey];
		const colorNormal = getThemedColorValue(color, 'normal');
		const colorHover = getThemedColorValue(color, 'hover');
		const colorActive = getThemedColorValue(color, 'active');

		return css`
			--color: ${colorNormal};
			--color-hover: ${colorHover};
			--color-active: ${colorActive};
		`;
	}

	const color: TextColor = ['text', 'normal'];
	const colorNormal = getThemedColorValue(color, 'normal');
	const colorHover = getThemedColorValue(color, 'hover');
	const colorActive = getThemedColorValue(color, 'active');

	return css`
		--color: ${colorNormal};
		--color-hover: ${colorHover};
		--color-active: ${colorActive};
	`;
};

const getHoverBackgroundContrastColorCSS = (backgroundColor: ShapeColor) => {
	if (backgroundColor === 'transparent') {
		return css`
			--color-hover: unset;
		`;
	}
	if (typeof backgroundColor === 'string') return undefined;

	const [categoryName, variantName] = backgroundColor;

	if (categoryName === 'positive') {
		const negativeTextKey = positiveKeysToNegativeTextKeys[variantName];
		const color: TextColor = ['text', negativeTextKey];
		const colorHover = getThemedColorValue(color, 'hover');

		return css`
			--color-hover: ${colorHover};
		`;
	}

	const color: TextColor = ['text', 'normal'];
	const colorHover = getThemedColorValue(color, 'hover');

	return css`
		--color-hover: ${colorHover};
	`;
};

const getActiveBackgroundContrastColorCSS = (backgroundColor: ShapeColor) => {
	if (backgroundColor === 'transparent') {
		return css`
			--color-active: unset;
		`;
	}
	if (typeof backgroundColor === 'string') return undefined;

	const [categoryName, variantName] = backgroundColor;

	if (categoryName === 'positive') {
		const negativeTextKey = positiveKeysToNegativeTextKeys[variantName];
		const color: TextColor = ['text', negativeTextKey];
		const colorActive = getThemedColorValue(color, 'active');

		return css`
			--color-active: ${colorActive};
		`;
	}

	const color: TextColor = ['text', 'normal'];
	const colorActive = getThemedColorValue(color, 'active');

	return css`
		--color-active: ${colorActive};
	`;
};

export const getNormalBackgroundColorCSS = (
	backgroundColor?: ShapeColor
): FlattenSimpleInterpolationMaybe => {
	if (backgroundColor == null) return;

	return css`
		${getNormalBackgroundContrastColorCSS(backgroundColor)};
		background-color: ${getThemedColorValue(backgroundColor, 'normal')};

		${hoverSelfSelector},
		${hoverDescendantsSelector} {
			background-color: ${getThemedColorValue(backgroundColor, 'hover')};
		}

		${activeSelfSelector},
		${activeDescendantsSelector} {
			background-color: ${getThemedColorValue(backgroundColor, 'active')};
		}
	`;
};

export const getHoverBackgroundColorCSS = (
	backgroundColor?: ShapeColor
): FlattenSimpleInterpolationMaybe => {
	if (backgroundColor == null) return;

	return css`
		${getHoverBackgroundContrastColorCSS(backgroundColor)};
		background-color: ${getThemedColorValue(backgroundColor, 'hover')};
	`;
};

export const getActiveBackgroundColorCSS = (
	backgroundColor?: ShapeColor
): FlattenSimpleInterpolationMaybe => {
	if (backgroundColor == null) return;

	return css`
		${getActiveBackgroundContrastColorCSS(backgroundColor)};
		background-color: ${getThemedColorValue(backgroundColor, 'active')};
	`;
};

export const getBoxShadowCSS = (
	boxShadow?: CSSProperty.BoxShadow
): FlattenSimpleInterpolationMaybe =>
	css`
		box-shadow: ${boxShadow};
	`;

export const getBackgroundClipCSS = (
	backgroundClip?: CSSProperty.BackgroundClip
): FlattenSimpleInterpolationMaybe =>
	css`
		background-clip: ${backgroundClip};
	`;

export const getBackdropFilterCSS = (
	backdropFilter?: CSSProperty.BackdropFilter
): FlattenSimpleInterpolationMaybe =>
	css`
		backdrop-filter: ${backdropFilter};
	`;
