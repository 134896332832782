import { SvgContent } from '@dop/ui-primitives/media/Svg';
import { translate } from '@dop/shared/translate/translate';

export const IconEmail: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '7 15 50 34', width: '50', height: '34' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		role="img"
		aria-hidden
		{...props}
	>
		<title>{translate('email')}</title>
		<g>
			<rect fill="none" width="64" height="64" />
		</g>
		<g>
			<path
				d={`M55,15H9c-1.1045,0-2,0.8955-2,2v30c0,1.1045,0.8955,2,2,2h46c1.1045,0,2-0.8955,2-2V17C57,15.8955,56.1045,15,55,15z
M51.0419,20L32,32.6016L12.9581,20H51.0419z M12,24.1625L30.8965,36.668C31.2305,36.8892,31.6152,37,32,37
c0.3848,0,0.7695-0.1108,1.1035-0.332L52,24.1625v19.7726l-11.835-8.6109c0,0-0.2412,0.1533-0.0244,0.418S46.9511,44,46.9511,44
H17.1689c0,0,6.5841-7.9775,6.7999-8.2422s-0.0127-0.4336-0.0127-0.4336L12.0318,44H12V24.1625z`}
			/>
		</g>
	</svg>
);
