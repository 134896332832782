// A collections of functions to manipulate immutable objects
// A check is performed to make sure the immutable object supports the method
// To make testing easier the functions that are a direct analog to immutable methods
// are split into ./immutableHelpersWithArgs.js and ./immutableHelpersWithoutArgs.js
import { useMemo } from 'react';
import { fromJS, List } from 'immutable';

import { pipe, existy, negate } from './functional';
import {
	findLastKey,
	withMutations,
	push,
	findKey,
	update,
	get,
	reduce,
	has,
	set,
	getIn,
	map,
} from './immutableHelpersWithArgs';
import { clear, toJS, toObject } from './immutableHelpersWithoutArgs';

export const getLastKey = findLastKey(() => true);

export const pipeWithMutations = pipe(pipe, withMutations);

export const pushWhenExisty = (value) =>
	existy(value) ? push(value) : (collection) => collection;

export const findAndUpdate = (predicate, updater) => (collection) => {
	const key = findKey(predicate)(collection);

	return existy(key) ? update(key, updater)(collection) : collection;
};

const pluckPropFrom = (oldCollection) => (newCollection, key) =>
	newCollection.set(key, oldCollection.get(key));

// make new collection with only props for keys
export const pluck =
	(...keys) =>
	(collection) =>
		withMutations((mutableCollection) =>
			keys.reduce(pluckPropFrom(collection), clear(mutableCollection))
		)(collection);

export const updaterFor = (key) => (updater) => update(key, updater);
export const setFor = (key) => (value) => set(key, value);
export const setForWhenExisty = (key) => (value) =>
	existy(value) ? set(key, value) : (collection) => collection;

export const setDefault = (key, value) =>
	update(key, (oldValue) => (existy(oldValue) ? oldValue : value));

export const reduceWithMutations = (reducer, memo) => (collection) =>
	withMutations((mutableMemo) => reduce(reducer, mutableMemo)(collection))(
		memo
	);

export const hasEvery =
	(...keys) =>
	(collection) =>
		keys.every((key) => has(key)(collection));

export const mapWithValueAsObject = (mapper) =>
	map((value, ...restParams) => {
		return mapper(toObject(value), ...restParams);
	});
export const getObjectIn = (...args) => pipe(getIn(...args), toObject);

export const getJSIn = (...args) => pipe(getIn(...args), toJS);

const existyForKeyPredicate = (collection) => (key) =>
	existy(get(key)(collection));
const existyForKeyPathPredicate = (collection) => (keyPath) =>
	existy(getIn(keyPath)(collection));

// Get value for first keyPath that has a value not other than null or undefined
export const getAnyIn =
	(...keyPaths) =>
	(collection) => {
		const keyPath = keyPaths.find(existyForKeyPathPredicate(collection));

		return existy(keyPath) ? getIn(keyPath)(collection) : undefined; // eslint-disable-line no-undefined
	};

// Get value for first key that has a value not other than null or undefined
export const getAny =
	(...keys) =>
	(collection) => {
		const key = keys.find(existyForKeyPredicate(collection));

		return existy(key) ? get(key)(collection) : undefined; // eslint-disable-line no-undefined
	};

/**
 * Check whether a given immutable iterable contains data.
 *
 * @alias Copied from helpers/functional.
 * @param iterable
 * @returns {boolean} - Returns true is the given immutable object contains data.
 */
export const isFilled = (iterable) =>
	existy(iterable) && iterable.isEmpty && !iterable.isEmpty();

/**
 * Check whether a given immutable iterable is empty.
 *
 * @alias Copied from helpers/functional.
 * @param iterable
 * @returns {boolean} - Returns true if the given immutable object is empty.
 */
export const isEmpty = negate(isFilled);

export const chunkList =
	(chunkSize) =>
	(list = List()) => {
		let chunkStart = 0;
		const listSize = list.count();
		const chunksMemo = [];

		while (chunkStart < listSize) {
			const chunkEnd = chunkStart + chunkSize;
			const chunk = list.slice(chunkStart, chunkEnd);
			chunksMemo.push(chunk);
			chunkStart = chunkEnd;
		}

		return List(chunksMemo);
	};

// Change all first-level object-props into ImmutableJS objects
// e.g. { foo: { type: 'ball', title: 'Voetbal' }, bar: 'Zoo' }
// =>   { foo: Map(...), bar: 'Zoo' }
// Wrapped in a useMemo to prevent unnecesary rerenders
export const useMapObjectPropsToImmutable = (obj) =>
	useMemo(
		() =>
			Object.entries(obj).reduce((acc, [key, value]) => {
				return { ...acc, [key]: fromJS(value) };
			}, {}),
		[obj]
	);
