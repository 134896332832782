import { Box, BoxProps } from '@dop/ui-primitives/planeDivision/Box';

type Props = Omit<BoxProps, 'id'>;

/**
 * This component is maintained for legacy-pages, but no longer a <main> element.
 * The layout (`@/app/layout.tsx`) already has that element, which wraps all legacy-pages
 * it is changed here to a regular `div` to prevent having a `<main>` in a `<main>`
 */
export const Main = ({ children, ...props }: Props): JSX.Element => {
	return (
		<Box
			as="div"
			$padding={[4, 0]}
			$position="relative"
			$inlineSize={'100%'}
			$flex={'1 0 auto'}
			{...props}
		>
			{children}
		</Box>
	);
};
