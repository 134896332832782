import {
	isNumber,
	unexisty,
	isNotNumber,
	negate,
	isNegativeNumber,
} from '../helpers/functional';
import { isFilledString } from '../helpers/string';

export const parseUnit = (entryUnit) => {
	if (unexisty(entryUnit)) {
		return { invalid: true };
	}

	if (isNumber(entryUnit)) {
		return { value: entryUnit, unit: '' };
	}

	const regex = /^(-?[\d\.]+)([a-z]+|%)?$/;
	const match = entryUnit.match(regex);

	if (!match) return { invalid: true };

	const [, valueString, unit = ''] = match;
	const value = Number(valueString);

	if (isNotNumber(value)) return { invalid: true };

	return { value, unit };
};

export const parseUnits = (...rawValues) => {
	let unitMemo = '';
	const valueMemo = [];

	for (const rawValue of rawValues) {
		const { value, unit, invalid } = parseUnit(rawValue);

		if (invalid) return { invalid: true };

		if (isFilledString(unitMemo) && isFilledString(unit) && unit !== unitMemo) {
			return { invalid: true };
		}

		unitMemo = isFilledString(unitMemo) ? unitMemo : unit;
		valueMemo.push(value);
	}

	return {
		values: valueMemo,
		unit: unitMemo,
	};
};

export const calc =
	(calculator) =>
	(...rawValues) => {
		const { values, unit, invalid } = parseUnits(...rawValues);

		if (invalid) return null;

		return `${values.reduce(calculator)}${unit}`;
	};

export const multiplyUnit = calc((a, b) => a * b);
export const divideUnit = calc((a, b) => a / b);
export const additionUnit = calc((a, b) => a + b);
export const subtractionUnit = calc((a, b) => a - b);

/* eslint-disable no-magic-numbers */
export const isNegativeUnit = (unit) => {
	if (unexisty(unit)) return false;
	if (isNumber(unit)) {
		return isNegativeNumber(unit);
	}

	return unit.charAt(0) === '-';
};

export const isPositiveUnit = negate(isNegativeUnit);

const isZeroValue = (value) => Math.sign(value) === 0;

export const isZero = (unit) => {
	if (unexisty(unit)) return unit;

	if (isFilledString(unit)) {
		return isZeroValue(unit.match(/[\d.]+/)[0]);
	}

	return isNumber(unit) ? isZeroValue(unit) : unit;
};

const toNegativeNumber = (number) => number * -1;
const toNegativeString = (string) => `-${string}`;

export const toNegative = (unit) => {
	if (unexisty(unit) || isZero(unit) || isNegativeUnit(unit)) {
		return unit;
	}

	return isNumber(unit) ? toNegativeNumber(unit) : toNegativeString(unit);
};
/* eslint-enable no-magic-numbers */
