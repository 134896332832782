import {
	divideUnit,
	multiplyUnit,
} from '@dop/shared/styleHelpers/unitCalculationHelpers';

export const space = '2rem';
/* eslint-disable no-magic-numbers */
export const doubleSpace = multiplyUnit(space, 2); // '4rem'
export const halfSpace = divideUnit(space, 2); // '1rem'
export const quarterSpace = divideUnit(space, 4); // '0.5rem'
export const eighthSpace = divideUnit(space, 16); // '0.25rem'

export const defaultTheme = {
	maxWidth: '100rem',
	space, // '2rem'
	doubleSpace,
	halfSpace,
	quarterSpace,
	eighthSpace,
	maxPageWidth: {
		em: '120em',
		rem: '96rem',
	},
	layoutPadding: {
		// 0rem instead of 0 so it can be used in css calc()
		all: '0rem',
		extraSmall: '3%',
		small: '1.6rem',
		mediumLarge: '1rem',
		extraLarge: '3.2rem',
		extraExtraExtraLarge: '4.1rem',
	},
};
/* eslint-enable */
