import styled, { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { StyleMap } from './StyleProps.types';
import { AccHide, ExcludeFlexPositionValues } from './baseDefinitions';
import {
	getAccHideCSS,
	getPositionCSS,
	getStringOrRootCapCSSValue,
} from './commonStyleFunctions';

type ScrollMarginSide = number | CSSProperty.ScrollMarginBlockStart;
/**
 * Styling props shared across all components
 */
export type BaseStaticStyleProps = Partial<{
	$position: Extract<
		CSSProperty.Position,
		CSSProperty.All | 'static' | 'relative'
	>;
	$display: Extract<CSSProperty.Display, 'none'>;
	$listStyleType: CSSProperty.ListStyleType;
	$isolation: CSSProperty.Isolation;
	$zIndex: number;
	$inlineSize: CSSProperty.InlineSize | number;
	$minInlineSize: CSSProperty.MinInlineSize | number;
	$maxInlineSize: CSSProperty.MaxInlineSize | number;
	$blockSize: CSSProperty.BlockSize | number;
	$minBlockSize: CSSProperty.MinBlockSize | number;
	$maxBlockSize: CSSProperty.MaxBlockSize | number;
	$aspectRatio: CSSProperty.AspectRatio | number;
	$transform: CSSProperty.Transform;
	$translate: CSSProperty.Translate;
	$rotate: CSSProperty.Rotate;
	$scale: CSSProperty.Scale;
	$transition: CSSProperty.Transition;
	$filter: CSSProperty.Filter;
	$backdropFilter: CSSProperty.BackdropFilter;
	$scrollMargin:
		| ScrollMarginSide
		| [ScrollMarginSide, ScrollMarginSide]
		| [ScrollMarginSide, ScrollMarginSide, ScrollMarginSide, ScrollMarginSide];
	$justifySelf: ExcludeFlexPositionValues<CSSProperty.JustifySelf>;
	$alignSelf: ExcludeFlexPositionValues<CSSProperty.AlignSelf>;
	$flex: CSSProperty.Flex;
	$gridRow: CSSProperty.GridRow | number;
	$gridColumn: CSSProperty.GridColumn | number;
	$gridArea: CSSProperty.GridArea;
	$accHide: AccHide;
	$writingMode: CSSProperty.WritingMode;
	$visibility: CSSProperty.Visibility;
	$overflow?: CSSProperty.Overflow;
	$pointerEvents?: CSSProperty.PointerEvents;
	$clipPath?: CSSProperty.ClipPath;
	$order?: CSSProperty.Order;
	$vars?: {
		[Key in `--${string}`]: string;
	};
}>;

export type BaseStyleProps = BaseStaticStyleProps;

export const baseStaticStyleMap: StyleMap<BaseStyleProps> = {
	$position: getPositionCSS,
	$display: (display) =>
		css`
			display: ${display};
		`,
	$listStyleType: (listStyleType = 'none') => css`
		list-style-type: ${listStyleType};
	`,
	$isolation: (isolation) =>
		isolation != null &&
		css`
			isolation: ${isolation};
		`,
	$zIndex: (zIndex) =>
		zIndex != null &&
		css`
			z-index: ${zIndex};
		`,
	$justifySelf: (justifySelf) =>
		justifySelf != null &&
		css`
			justify-self: ${justifySelf};
		`,
	$alignSelf: (alignSelf) =>
		alignSelf != null &&
		css`
			align-self: ${alignSelf};
		`,
	$flex: (flex) =>
		flex != null &&
		css`
			flex: ${flex};
		`,
	$inlineSize: (inlineSize) =>
		inlineSize != null &&
		css`
			inline-size: ${getStringOrRootCapCSSValue(inlineSize)};
		`,
	$minInlineSize: (minInlineSize) =>
		minInlineSize != null &&
		css`
			min-inline-size: ${getStringOrRootCapCSSValue(minInlineSize)};
		`,
	$maxInlineSize: (maxInlineSize) =>
		maxInlineSize != null &&
		css`
			max-inline-size: ${getStringOrRootCapCSSValue(maxInlineSize)};
		`,
	$blockSize: (blockSize) =>
		blockSize != null &&
		css`
			block-size: ${getStringOrRootCapCSSValue(blockSize)};
		`,
	$minBlockSize: (minBlockSize) =>
		minBlockSize != null &&
		css`
			min-block-size: ${getStringOrRootCapCSSValue(minBlockSize)};
		`,
	$maxBlockSize: (maxBlockSize) =>
		maxBlockSize != null &&
		css`
			max-block-size: ${getStringOrRootCapCSSValue(maxBlockSize)};
		`,
	$aspectRatio: (aspectRatio) =>
		aspectRatio != null &&
		css`
			aspect-ratio: ${aspectRatio};
		`,
	$transform: (transform) =>
		transform != null &&
		css`
			transform: ${transform};
		`,
	$translate: (translate) =>
		translate != null &&
		css`
			translate: ${translate};
		`,
	$rotate: (rotate) =>
		rotate != null &&
		css`
			rotate: ${rotate};
		`,
	$scale: (scale) =>
		scale != null &&
		css`
			scale: ${scale};
		`,
	$transition: (transition) =>
		transition != null &&
		css`
			transition: ${transition};
		`,
	$filter: (filter) =>
		filter != null &&
		css`
			filter: ${filter};
		`,
	$backdropFilter: (backdropFilter) =>
		backdropFilter != null &&
		css`
			backdrop-filter: ${backdropFilter};
		`,
	$scrollMargin: (scrollMargin) => {
		if (scrollMargin == null) return;

		if (Array.isArray(scrollMargin)) {
			const scrollMarginPropertyValues = scrollMargin.map(
				(scrollMarginEntry) => {
					return getStringOrRootCapCSSValue(scrollMarginEntry);
				}
			);
			if (scrollMarginPropertyValues.length === 4) {
				const [
					scrollMarginBlockStart,
					scrollMarginInlineEnd,
					scrollMarginBlockEnd,
					scrollMarginInlineStart,
				] = scrollMarginPropertyValues;
				return css`
					scroll-margin-inline: ${scrollMarginInlineStart}
						${scrollMarginInlineEnd};
					scroll-margin-block: ${scrollMarginBlockStart} ${scrollMarginBlockEnd};
				`;
			}

			const [scrollMarginBlock, scrollMarginInline] =
				scrollMarginPropertyValues;

			return css`
				scroll-margin-inline: ${scrollMarginInline};
				scroll-margin-block: ${scrollMarginBlock};
			`;
		}
		return css`
			scroll-margin-inline: ${scrollMargin};
			scroll-margin-block: ${scrollMargin};
		`;
	},
	$gridRow: (gridRow) =>
		gridRow != null &&
		css`
			grid-row: ${gridRow};
		`,
	$gridColumn: (gridColumn) =>
		gridColumn != null &&
		css`
			grid-column: ${gridColumn};
		`,
	$gridArea: (gridArea) =>
		gridArea != null &&
		css`
			grid-area: ${gridArea};
		`,
	$accHide: getAccHideCSS,
	$writingMode: (writingMode) =>
		writingMode != null &&
		css`
			writing-mode: ${writingMode};
		`,
	$visibility: (visibility) =>
		visibility != null &&
		css`
			visibility: ${visibility};
		`,
	$overflow: (overflow) =>
		overflow != null &&
		css`
			overflow: ${overflow};
		`,
	$pointerEvents: (pointerEvents) =>
		pointerEvents != null &&
		css`
			pointer-events: ${pointerEvents};
		`,
	$clipPath: (clipPath) =>
		clipPath != null &&
		css`
			clip-path: ${clipPath};
		`,
	$order: (order) =>
		order != null &&
		css`
			order: ${order};
		`,
	$vars: (vars) => {
		if (vars == null) return undefined;

		return Object.entries(vars).map(
			([customPropertyName, customPropertyValue]) => {
				return css`
					${customPropertyName}: ${customPropertyValue}
				`;
			}
		);
	},
};

export const baseStyle = css`
	margin: 0;
	padding: 0;
	background: none;
	border: none;
	color: inherit;
	font-weight: inherit;
	line-height: inherit;
	text-transform: none;
`;

export const BaseComponent = styled.span`
	${baseStyle}
`;
